import React from 'react'
import * as Wrapper from './Styles'

const Header = ({ LeftArea, RightArea }) => {
  return (
    <Wrapper.DashBoardHeaderStyle1 data-testid="overview-header-section">
      <Wrapper.DashBoardHeaderStyle2> {LeftArea}</Wrapper.DashBoardHeaderStyle2>
      <Wrapper.DashBoardHeaderStyle3>{RightArea}</Wrapper.DashBoardHeaderStyle3>
    </Wrapper.DashBoardHeaderStyle1>
  )
}

export default Header
