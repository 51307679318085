import { useEffect, useState } from 'react'
import { getProps } from '../utils/commonMethods'
import { DevicesClient } from '../client/DevicesClient'
import { getEnvConfig } from '../utils/consts'

export const useDevices = (stack) => {
  const [isFetching, setIsFetching] = useState(false)
  const [devicesCount, setDevicesCount] = useState(null)
  const props = getProps()
  const [error, setError] = useState(null)
  const config = getEnvConfig(stack)
  const devicesClient = new DevicesClient(
    stack,
    config.deviceApiUrl,
    props.authProvider
  )
  const fetchDevicesAPI = async () => {
    setIsFetching(true)
    setError(null)
    try {
      const totalOfflineCount = await devicesClient.exportDeviceCountApi(
        'connectionState=offline&'
      )
      const totalOnlineCount = await devicesClient.exportDeviceCountApi(
        'connectionState=online&'
      )
      const totalDeviceCount = await devicesClient.exportDeviceCountApi()
      const countData = await Promise.all([
        {
          offlineCount: totalOfflineCount,
          onlineCount: totalOnlineCount,
          totalCount: totalDeviceCount
        }
      ])
      setDevicesCount(countData[0])
      setIsFetching(false)
    } catch (err) {
      setIsFetching(false)
      setError(err)
      console.log(err)
    }
  }

  useEffect(() => {
    fetchDevicesAPI()
  }, [])

  return {
    devicesCount,
    isFetching,
    fetchDevicesAPI,
    error
  }
}
