import styled from 'styled-components'
//import tokens from '@veneer/tokens';

export const dashboardSecuritySummary = styled.div`
  align-items: center;
  display: flex;
  width: 183px;
  height: 24px;
  white-space: nowrap;
`

export const overviewDonutDivider = styled.div`
  @media only screen and (max-width: 1220px) {
    border-bottom: 3px solid #ebe7e7;
  }
  border-right: 3px solid #ebe7e7;
  margin: 29.5px 24px 29.5px 24px;
`

export const dashboardSecuritySummaryBlankCard = styled.div`
  height: 350px;
`

export const dashboardHeder = styled.div`
  align-items: center;
  display: flex;
`

export const dashboardOverviewNoInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const dashboardOverviewDonutSummary = styled.div`
  display: flex;
  @media only screen and (max-width: 1220px) {
    display: flex;
    flex-direction: column;
  }
`
export const assessmentSummaryCard = styled.div`
  width: 100%;
  transition: width 0.5s ease; /* Add transition property */
    @media only screen and (max-width: 350px) and (min-width: 200px){
    width: 52%;
  }
   @media only screen and (max-width: 400px) and (min-width: 351px){
    width: 57%;
  }
   @media only screen and (max-width: 450px) and (min-width: 401px){
    width: 65%;
  }
  @media only screen and (max-width: 500px) and (min-width: 451px){
    width: 75%;
  }
  @media only screen and (max-width: 557px) and (min-width: 501px){
    width: 85%;
  }
  @media only screen and (max-width: 600px) and (min-width: 558px){
    width: 95%;
  }
`