import { JarvisAuthProvider } from '@jarvis/web-http'

type ManageDeviceProps = {
  country?: string
  language?: string
  authProvider: JarvisAuthProvider
}
let localprops: ManageDeviceProps = {
  language: 'en',
  country: 'US',
  authProvider: null,
};

export const setProps = (props) => {
  localprops = props;
};

export const getProps = (): ManageDeviceProps => {
  return localprops;
};

export const getDateAndTime = () => {
  const today = new Date();
  const locale: string = 'en';
  const dateAndTime = `${today.getDate()}
                ${today.toLocaleDateString(locale, { month: 'long' })}
                ${today.toLocaleDateString(locale, {
    year: 'numeric',
  })} | ${today.toLocaleTimeString(locale, {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
    second: 'numeric',
  })}`;
  return dateAndTime;
};

