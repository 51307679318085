import { JarvisWebHttpClient } from '@jarvis/web-http'
import { sprintf } from 'sprintf-js'
import { getProps } from '../utils/commonMethods'

export class DashboardClient {
  baseUrl
  days
  apiversion = '1'
  jarvisWebClient
  constructor(baseUrl, days) {
    const props = getProps()
    this.baseUrl = baseUrl
    this.days = days

    this.jarvisWebClient = new JarvisWebHttpClient({
      defaultBaseURL: this.baseUrl,
      defaultAuthProvider: props.authProvider
    })
  }
  isStatusCodeOk(status) {
    return status > 199 && status < 400
  }

  async getAssessmentSummary() {
    const response = await this.jarvisWebClient.get({
      url: this.securitySummaryEndPoint()
      // headers: {
      //   Authorization: 'Bearer ' + this.varToken
      // }
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }

  async getAssessmentActivity() {
    const response = await this.jarvisWebClient.get({
      url: this.securityActivityEndPoint()
      // headers: {
      //   Authorization: 'Bearer ' + this.varToken
      // }
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }

  securitySummaryEndPoint() {
    return sprintf(
      '%s/v%s/securityAssessmentSummary?when=%s',
      this.baseUrl,
      this.apiversion,
      this.days
    )
  }

  securityActivityEndPoint() {
    return sprintf(
      '%s/v%s/securityAssessmentActivity?since=%s',
      this.baseUrl,
      this.apiversion,
      this.days
    )
  }
}
