import styled from 'styled-components'

export const DashBoardHeaderStyle1 = styled.div`
  border-bottom: 1px solid #e2e0e0;
  display: flex;
  width: 100%;
`

export const DashBoardHeaderStyle2 = styled.div`
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
`

export const DashBoardHeaderStyle3 = styled.div`
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  position: static;
  width: 160px;
  height: 36px;
  top: 10px;
  margin-left: auto;
`
