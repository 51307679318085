import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tokens from '@veneer/tokens';
import GenericChart from '../GenericChart';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  buildTooltipTitleMapping,
  getPointSelected,
  getPointsAverage,
  getPointsWithoutAverage,
  getTooltipAverage,
  getTooltipGeneralAverage,
} from '../utils';

const SERIES_IDS = {
  SCANS: 0,
  AVERAGE: 1,
};

const ScanChart = ({
  chartData,
  error,
  loading,
  onRetry,
  pointStartAverage,
  selectedYear,
  i18n,
  noData,
}) => {
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.xs);

  const isDeviceSupportTouch = ('ontouchstart' in window)
                                || (navigator.maxTouchPoints > 0)
                                || (navigator.msMaxTouchPoints > 0);

  const colorsDefault = useMemo(() => ((['#269EB2', '#3F3B5C'])), []);
  const colorsHighlighted = useMemo(() => ((['#2AC5E0'])), []);

  const legendTitles = useMemo(() => ({
    [SERIES_IDS.AVERAGE]: i18n.legend.average,
    [SERIES_IDS.SCANS]: i18n.tooltip.scanLabel,
  }), [i18n.legend.average, i18n.tooltip.scanLabel]);

  const tooltipXAxisMapping = useMemo(() => buildTooltipTitleMapping(
    i18n.xAxis.categories, i18n.tooltip.categories,
  ),
  [i18n.xAxis.categories, i18n.tooltip.categories]);

  const tooltip = useMemo(() => ({
    shared: sharedTooltip,
    followPointer: sharedTooltip,
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      const chart = this;

      let pointSelected = chart.point;

      if (!pointSelected) {
        pointSelected = getPointSelected(chart.points);
      }

      if (!!pointSelected && pointSelected.series.userOptions?.isTooltipAverage === true) {
        return getTooltipAverage(pointSelected);
      }

      const pointsAverage = getPointsAverage(chart?.points);

      const tooltipTitle = tooltipXAxisMapping[chart.x];
      const title = tooltipTitle ? `<b>${tooltipTitle}</b><br/>` : '';

      return getTooltipGeneralAverage(pointsAverage, isDeviceSupportTouch) + getPointsWithoutAverage(chart.points).reduce((s, point) => `${s}${legendTitles[point.series.userOptions.id]}: ${point.y}`,
        title);
    },
  }), [
    isDeviceSupportTouch,
    legendTitles,
    sharedTooltip,
    tooltipXAxisMapping,
  ]);

  const {
    counters,
    average,
    maxAxisY,
  } = chartData;

  const numCategories = i18n.xAxis.categories.length;

  const options = useMemo(() => ({
    plotOptions: {
      column: {
        borderRadiusTopLeft: average ? tokens.borderRadius2 : 0,
        borderRadiusTopRight: average ? tokens.borderRadius2 : 0,
        pointWidth: 16,
      },
      series: {
        marker: {
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null, // inherit from series
        },
      },
    },
    legend: {
      reversed: true,
    },
    yAxis: {
      ...(average === null || average === 0 ? {
        minRange: 0.1,
        tickInterval: 1,
      } : {}),
      min: 0,
      max: isMobile ? maxAxisY : null,
      title: {
        text: i18n.yAxis,
      },
    },
    xAxis: {
      min: 0,
      max: isMobile ? 4 : numCategories,
      scrollbar: {
        enabled: isMobile,
      },
      categories: i18n.xAxis.categories,
    },
    series: [
      {
        id: SERIES_IDS.AVERAGE,
        type: 'line',
        name: i18n.legend.average,
        data: counters.slice(pointStartAverage).map(val => (val === null ? val : average)),
        color: colorsDefault[1],
        zIndex: 1,
        stickyTracking: false,
        pointStart: pointStartAverage,
        selectedYear,
        isTooltipAverage: true,
        pointPlacement: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      },
      {
        id: SERIES_IDS.SCANS,
        name: i18n.legend.scans,
        data: counters,
        type: 'column',
        color: colorsDefault[0],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[0],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[0],
            });
          },
        },
      },
    ],
  }), [
    average,
    colorsDefault,
    colorsHighlighted,
    i18n.legend.average,
    i18n.legend.scans,
    i18n.yAxis,
    i18n.xAxis.categories,
    isMobile,
    maxAxisY,
    counters,
    numCategories,
    pointStartAverage,
    selectedYear,
  ]);

  return (
    <GenericChart
      data-testid="usage-scan"
      error={error}
      loading={loading}
      onRetry={onRetry}
      options={options}
      tooltip={tooltip}
      noData={noData}
      i18n={{
        error: i18n.error,
        retry: i18n.retry,
        noDataAvailable: i18n.noDataAvailable,
      }}
    />
  );
};

ScanChart.defaultProps = {
  noData: false,
  pointStartAverage: 0,
};

ScanChart.propTypes = {
  chartData: PropTypes.shape({
    counters: PropTypes.arrayOf(PropTypes.number).isRequired,
    average: PropTypes.number.isRequired,
    maxAxisY: PropTypes.number,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  noData: PropTypes.bool,
  onRetry: PropTypes.func.isRequired,
  pointStartAverage: PropTypes.number,
  selectedYear: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    legend: PropTypes.shape({
      average: PropTypes.string.isRequired,
      scans: PropTypes.string.isRequired,
    }).isRequired,
    xAxis: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    yAxis: PropTypes.string.isRequired,
    tooltip: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      scanLabel: PropTypes.string.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
    noDataAvailable: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ScanChart;
