import React, { useState, useEffect } from 'react'
import {
  Card,
  List,
  ProgressIndicator,
  Select,
  IconArrowDown,
  IconArrowUp,
  IconMinus,
  IconPrinter,
  IconShieldCheckmark,
  IconWrench
} from '@veneer/core'
import IconShieldArrowDown from '../../Icons/IconShieldArrowDown'
import IconShieldArrowUp from '../../Icons/IconShielArrowUp'
import IconShieldArrowMinus from '../../Icons/IconShieldArrowMinus'
import Header from '../Header'
import * as Wrapper from './Styles'
import { useI18n } from '@jarvis/react-portal-addons'

export const SecurityAssessmentActivity = (props) => {
  const { t } = useI18n()
  const { colors, styles } = props
  const [sumarydata, setSummaryData] = useState<any>([])
  const [activitydata, setActivityData] = useState<any>([])
  const [sumary0dData, setSummary0dData] = useState<any>([])
  const [pastDaysValue, setDaysValue] = useState<any>([1])

  useEffect(() => {
    if (props.summaryData[0]) setSummaryData(props.summaryData[0])
    if (props.activityData[0]) setActivityData(props.activityData[0])
    if (props.setDataFor0d[0]) setSummary0dData(props.setDataFor0d)
  }, [props])

  useEffect(() => {
    if (pastDaysValue[0] == 1) {
      props.setTime('1d')
    }
    if (pastDaysValue[0] == 2) {
      props.setTime('7d')
    }
    if (pastDaysValue[0] == 3) {
      props.setTime('30d')
    }
  }, [pastDaysValue])

  const complianceRateIcon = (value) => {
    if (value < 0) {
      return <IconShieldArrowDown data-testid="overview-shield-arrow-down" />
    }
    if (value > 0) {
      return <IconShieldArrowUp data-testid="overview-shield-arrow-up" />
    }
    if (value === 0) {
      return <IconShieldArrowMinus data-testid="overview-shield-arrow-minus" />
    }
    return <IconShieldArrowMinus data-testid="overview-shield-arrow-minus" />
  }

  const complianceRateValue = (value) => {
    if (value < 0) {
      return <div>{Math.ceil(value)}</div>
    }
    if (value > 0) {
      return <div>+{Math.floor(value)}</div>
    }
    return 0
  }

  const flexDisplay = (value) => {
    return <div>{value}</div>
  }

  const textcolor = (value) => {
    if (value > 0) {
      return colors[1]
    } else if (value < 0) {
      return colors[2]
    } else return colors[0]
  }

  const checkRisk = (value) => {
    let percentValue
    if (value < 0) {
      percentValue = (
        <Wrapper.DashBoardCheckRisk Colors={colors} Styles={styles}>
          <Wrapper.DashBoardCheckRiskstyle1>
            {value}%
          </Wrapper.DashBoardCheckRiskstyle1>
          <Wrapper.DashBoardCheckRiskstyle2>
            <IconArrowDown
              data-testid="overview-icon-arrow-down"
              color="colorGreen6"
              size={24}
              filled
            />
          </Wrapper.DashBoardCheckRiskstyle2>
        </Wrapper.DashBoardCheckRisk>
      )
    } else if (value > 0) {
      percentValue = (
        <Wrapper.DashBoardpercentValue Colors={colors} Styles={styles}>
          <Wrapper.DashBoardpercentValueStyle1>
            +{value}%
          </Wrapper.DashBoardpercentValueStyle1>
          <Wrapper.DashBoardpercentValueStyle2>
            <IconArrowUp
              data-testid="overview-icon-arrow-up"
              color="colorRed6"
              size={24}
              filled
            />
          </Wrapper.DashBoardpercentValueStyle2>
        </Wrapper.DashBoardpercentValue>
      )
    } else {
      percentValue = (
        <Wrapper.dashboardpercentValue3 Colors={colors} Styles={styles}>
          <Wrapper.DashBoardPercentvalueElse>
            {' '}
            0.00%{' '}
          </Wrapper.DashBoardPercentvalueElse>
          <Wrapper.DashBoardPercentvalueElse_1>
            <IconMinus
              data-testid="overview-icon-minus"
              color="colorGray5"
              size={17}
              filled
            />
          </Wrapper.DashBoardPercentvalueElse_1>
        </Wrapper.dashboardpercentValue3>
      )
    }
    return percentValue
  }

  const showAssesmentStatus = (status) => {
    const statusSwitch = status.toLowerCase()
    switch (statusSwitch) {
      case 'high risk':
        return <Wrapper.DashBoardOverviewMenuCircular1 />
      case 'medium risk':
        return <Wrapper.DashBoardOverviewMenuCircular2 />
      case 'low risk':
        return <Wrapper.DashBoardOverviewMenuCircular3 />
      case 'not assessed':
        return <Wrapper.DashBoardOverviewMenuCircular4 />
    }
  }

  const currentCalculation = (numerator, denominator) => {
    return denominator !== 0 ? (numerator * 100) / denominator : 0
  }

  const selectedDayCalculation = (num, den) => {
    return den !== 0 ? (num * 100) / den : 0
  }

  const securityTrends = () => {
    const newDevicesEnrolled = activitydata.fleetActivity?.newDevices
    const devicesAutoRemediated = activitydata.assessmentActivity?.remediated

    const totalcurrent = parseInt(sumary0dData[0]?.total, 10)
    const totalxdays = parseInt(sumarydata.total, 10)
    const compliantcurrent = parseInt(sumary0dData[0]?.compliant, 10)
    const compliantxdays = parseInt(sumarydata.compliant, 10)

    const highRiskcurrent = parseInt(
      sumary0dData[0]?.nonCompliant?.highRisk,
      10
    )
    const highRiskxdays = parseInt(sumarydata.nonCompliant?.highRisk, 10)
    const mediumRiskcurrent = parseInt(
      sumary0dData[0]?.nonCompliant?.mediumRisk,
      10
    )
    const mediumRiskxdays = parseInt(sumarydata.nonCompliant?.mediumRisk, 10)
    const lowRiskcurrent = parseInt(sumary0dData[0]?.nonCompliant?.lowRisk, 10)
    const lowRiskxdays = parseInt(sumarydata.nonCompliant?.lowRisk, 10)

    const disconnected = parseInt(
      sumary0dData[0]?.notAssessed?.disconnected,
      10
    )
    const disconnected1 = parseInt(sumarydata.notAssessed?.disconnected, 10)
    const unknown = parseInt(sumary0dData[0]?.notAssessed?.unknown, 10)
    const unknown1 = parseInt(sumarydata.notAssessed?.unknown, 10)

    var changeInComplianceRate =
      currentCalculation(compliantcurrent, totalcurrent) -
      selectedDayCalculation(compliantxdays, totalxdays)

    var changeInHighRisk = parseInt(
      (
        currentCalculation(highRiskcurrent, totalcurrent) -
        selectedDayCalculation(highRiskxdays, totalxdays)
      ).toFixed(1)
    )

    var changeInMediumRisk = parseInt(
      (
        currentCalculation(mediumRiskcurrent, totalcurrent) -
        selectedDayCalculation(mediumRiskxdays, totalxdays)
      ).toFixed(1)
    )

    var changeInLowRisk = parseInt(
      (
        currentCalculation(lowRiskcurrent, totalcurrent) -
        selectedDayCalculation(lowRiskxdays, totalxdays)
      ).toFixed(1)
    )

    var notAssessedChange = parseInt(
      (
        currentCalculation(disconnected + unknown, totalcurrent) -
        selectedDayCalculation(disconnected1 + unknown1, totalxdays)
      ).toFixed(1)
    )

    const contentColor = textcolor(changeInComplianceRate)
    return (
      <div className="dashboard-endpointSecurity-activity">
        <Header
          data-testid="overview-header-section"
          LeftArea={t(
            'ecp-endpointsecurity-dashboard.securityTrends.header',
            'Security Trends'
          )}
          RightArea={
            <Wrapper.SelectWrapper>
              <Select
                data-testid="select-menu-dropdown"
                options={[
                  {
                    value: 1,
                    label: t(
                      'ecp-endpointsecurity-dashboard.securityTrends.timeSpan.24hrtime',
                      'Last 24 hours'
                    )
                  },
                  {
                    value: 2,
                    label: t(
                      'ecp-endpointsecurity-dashboard.securityTrends.timeSpan.7daystime',
                      'Last 7 days'
                    )
                  },
                  {
                    value: 3,
                    label: t(
                      'ecp-endpointsecurity-dashboard.securityTrends.timeSpan.30daystime',
                      'Last 30 days'
                    )
                  }
                ]}
                value={pastDaysValue}
                onChange={({ value: v }) => setDaysValue([v])}
                clearIcon={false}
              />
            </Wrapper.SelectWrapper>
          }
        />

        <Wrapper.DashboardEndpointSecurityActivityGrid id="overview-trendsactivity">
          <List
            data-testid="overview-list-change"
            gutter="4"
            background="filled"
            border="none"
          >
            <Wrapper.DashboardListItem
              data-testid="overview-listitem-change-0"
              leadingArea={
                <div style={{ paddingBottom: 2 }}>
                  {complianceRateIcon(changeInComplianceRate)}
                </div>
              }
              centerArea={
                <div
                  style={{
                    fontSize: styles[4],
                    zIndex: 4
                  }}
                >
                  <div
                    style={{
                      color: contentColor,

                      fontSize: '24px',
                      lineHeight: '28px',
                      display: 'flex',
                      height: '28px'
                    }}
                  >
                    {!isNaN(changeInComplianceRate) ? (
                      complianceRateValue(changeInComplianceRate)
                    ) : (
                      <ProgressIndicator />
                    )}
                    %
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#212121'
                    }}
                  >
                    {t(
                      'ecp-endpointsecurity-dashboard.securityTrends.securityActivity.complianceRate',
                      'Change in Compliance Rate'
                    )}
                  </div>
                </div>
              }
              trailingArea={null}
            />
            <Wrapper.DashboardListItem
              data-testid="overview-listItem-change-1"
              leadingArea={
                <div style={{ paddingBottom: 2 }}>
                  <IconShieldCheckmark
                    data-testid="overview-icon-shield-check"
                    size={35}
                  />
                </div>
              }
              centerArea={
                <div
                  style={{
                    fontSize: styles[4],
                    zIndex: 3
                  }}
                >
                  <div
                    style={{
                      color: colors[1],
                      fontSize: '24px',
                      lineHeight: '28px',
                      height: '28px'
                    }}
                  >
                    {!isNaN(compliantcurrent) ? (
                      flexDisplay(compliantcurrent)
                    ) : (
                      <ProgressIndicator />
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#212121'
                    }}
                  >
                    {t(
                      'ecp-endpointsecurity-dashboard.securityTrends.securityActivity.fleetCompliance',
                      'Fleet Remains Compliant'
                    )}
                  </div>
                </div>
              }
              trailingArea={null}
            />
            <Wrapper.DashboardListItem
              data-testid="overview-listItem-change-2"
              leadingArea={
                <div style={{ paddingBottom: 2 }}>
                  <IconPrinter data-testid="overview-icon-printer" size={32} />
                </div>
              }
              centerArea={
                <div
                  style={{
                    fontSize: styles[4],
                    zIndex: 2
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '28px',
                      height: '28px'
                    }}
                  >
                    {!isNaN(newDevicesEnrolled) ? (
                      complianceRateValue(newDevicesEnrolled)
                    ) : (
                      <ProgressIndicator />
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#212121'
                    }}
                  >
                    {t(
                      'ecp-endpointsecurity-dashboard.securityTrends.securityActivity.deviceEnrolled',
                      'New Devices Enrolled'
                    )}
                  </div>
                </div>
              }
              trailingArea={null}
            />
            <Wrapper.DashboardListItem
              data-testid="overview-listItem-change-3"
              leadingArea={
                <div style={{ paddingBottom: 2 }}>
                  <IconWrench data-testid="overview-icon-wrench" size={32} />
                </div>
              }
              centerArea={
                <div
                  style={{
                    fontSize: styles[4],
                    zIndex: 1
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '28px',
                      height: '28px'
                    }}
                  >
                    {!isNaN(devicesAutoRemediated) ? (
                      flexDisplay(devicesAutoRemediated)
                    ) : (
                      <ProgressIndicator />
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#212121'
                    }}
                  >
                    {t(
                      'ecp-endpointsecurity-dashboard.securityTrends.securityActivity.deviceAutoRemediated',
                      'Device Auto-Remediated'
                    )}
                  </div>
                </div>
              }
              trailingArea={null}
            />
          </List>
          <Wrapper.DashboardDonutdividerOverview />
          <List
            data-testid="overview-assessment-list"
            gutter="4"
            background="filled"
            border="none"
          >
            <p
              style={{
                textAlign: 'center',
                marginBottom: '34px',

                fontSize: '20px',
                lineHeight: '24px'
              }}
            >
              {t(
                'ecp-endpointsecurity-dashboard.securityTrends.assessmentRate.header',
                'Assessment Rate'
              )}
            </p>

            <Wrapper.DashboardListItem
              data-testid="overview-assessment-listItem-0"
              leadingArea={showAssesmentStatus('high risk')}
              centerArea={t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.highStatus',
                'High Risk'
              )}
              trailingArea={
                <div style={{ marginRight: '5.8px' }}>
                  {!isNaN(changeInHighRisk) ? (
                    checkRisk(changeInHighRisk)
                  ) : (
                    <ProgressIndicator />
                  )}
                </div>
              }
            />
            <Wrapper.DashboardListItem
              data-testid="overview-assessment-listItem-1"
              leadingArea={showAssesmentStatus('medium risk')}
              centerArea={t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.mediumStatus',
                'Medium Risk'
              )}
              trailingArea={
                <div style={{ marginRight: '5.8px' }}>
                  {!isNaN(changeInMediumRisk) ? (
                    checkRisk(changeInMediumRisk)
                  ) : (
                    <ProgressIndicator />
                  )}
                </div>
              }
            />
            <Wrapper.DashboardListItem
              data-testid="overview-assessment-listItem-2"
              leadingArea={showAssesmentStatus('low risk')}
              centerArea={t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.lowStatus',
                'Low Risk'
              )}
              trailingArea={
                <div style={{ marginRight: '5.8px' }}>
                  {!isNaN(changeInLowRisk) ? (
                    checkRisk(changeInLowRisk)
                  ) : (
                    <ProgressIndicator />
                  )}
                </div>
              }
            />
            <Wrapper.DashboardListItem
              data-testid="overview-assessment-listItem-3"
              leadingArea={showAssesmentStatus('not assessed')}
              centerArea={t(
                'ecp-endpointsecurity-dashboard.securityTrends.assessmentRate.notAssessedRiskRate',
                'Not Assessed'
              )}
              trailingArea={
                <div style={{ marginRight: '5.8px' }}>
                  {!isNaN(notAssessedChange) ? (
                    checkRisk(notAssessedChange)
                  ) : (
                    <ProgressIndicator />
                  )}
                </div>
              }
            />
          </List>
        </Wrapper.DashboardEndpointSecurityActivityGrid>
      </div>
    )
  }
  const blankCardActivity = () => {
    return (
      <Wrapper.DashboarddashboardEndpointSecurityActivityBlankCard>
        <Header
          data-testid="overview-header-section"
          LeftArea={t(
            'ecp-endpointsecurity-dashboard.securityTrends.header',
            'Security Trends'
          )}
          RightArea={
            <Wrapper.SelectWrapper>
              <Select
                data-testid="select-menu-dropdown"
                // css={{ width: '160px' }}
                options={[
                  {
                    value: 1,
                    label: t(
                      'ecp-endpointsecurity-dashboard.securityTrends.timeSpan.24hrtime',
                      'Last 24 hours'
                    )
                  },
                  {
                    value: 2,
                    label: t(
                      'ecp-endpointsecurity-dashboard.securityTrends.timeSpan.7daystime',
                      'Last 7 days'
                    )
                  },
                  {
                    value: 3,
                    label: t(
                      'ecp-endpointsecurity-dashboard.securityTrends.timeSpan.30daystime',
                      'Last 30 days'
                    )
                  }
                ]}
                value={pastDaysValue}
                onChange={({ value: v }) => setDaysValue([v])}
                clearIcon={false}
              />
            </Wrapper.SelectWrapper>
          }
        />
        <Wrapper.DashboarddashboardProgressIndicator>
          <ProgressIndicator />
        </Wrapper.DashboarddashboardProgressIndicator>
      </Wrapper.DashboarddashboardEndpointSecurityActivityBlankCard>
    )
  }

  if (
    Object.keys(sumarydata).length >= 1 &&
    Object.keys(activitydata).length >= 1
  ) {
    return (
      <Wrapper.assessmentActivityCard>
      <Card
        data-testid="overview-card"
        content={securityTrends()}
        background="filled"
        border="dropShadow"
      />
      </Wrapper.assessmentActivityCard>
    )
  } else {
    return (
      <Wrapper.assessmentActivityCard>
      <Card
        data-testid="blank-overview-card"
        content={blankCardActivity()}
        background="filled"
        border="dropShadow"
      />
      </Wrapper.assessmentActivityCard>
    )
  }
}
