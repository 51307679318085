const environmentVariables = {
  dev: {
    deviceApiUrl: 'https://dev-us1.api.ws-hp.com/devicecache',
    SSApiUrl: 'https://dev-us1.api.ws-hp.com/securitymgrfleet'
  },
  pie: {
    deviceApiUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
    SSApiUrl: 'https://pie-us1.api.ws-hp.com/securitymgrfleet'
  },
  stage: {
    deviceApiUrl: 'https://stage-us1.api.ws-hp.com/devicecache',
    SSApiUrl: 'https://stage-us1.api.ws-hp.com/securitymgrfleet'
  },
  prod: {
    deviceApiUrl: 'https://us1.api.ws-hp.com/devicecache',
    SSApiUrl: 'https://us1.api.ws-hp.com/securitymgrfleet'
  }
}

export function getEnvConfig(stack) {
  switch (stack) {
    case 0:
      return environmentVariables.dev
    case 1:
      return environmentVariables.pie
    case 2:
      return environmentVariables.stage
    case 3:
      return environmentVariables.prod
    default:
      return environmentVariables.pie
  }
}
export const FLEET_READ_SCOPE = 'ws-hp.com/smcloud/fleetassessment.READ'
export const DEVICE_OWNER_SCOPE = 'ws-hp.com/devicecache/devices.OWNER'

export const TREND_CARD_SCOPES = [{ scope: FLEET_READ_SCOPE }]
export const STATUS_CARD_SCOPES = [
  { scope: DEVICE_OWNER_SCOPE },
  { scope: FLEET_READ_SCOPE }
]
export const SUMMARY_CARD_SCOPES = [{ scope: FLEET_READ_SCOPE }]

export const Colors = {
  green1: '#57AEA4',
  green2: '#1C7A17',
  red1: '#D41E4F',
  red2: '#BE1313',
  orange: '#F35D3E',
  yellow: '#FFCE33',
  grey: '#ADADAD',
  black1: '#212121',
  black2: '#404040',
  purple: '#7300A9'
}
