import styled from 'styled-components'
import { ListItem } from '@veneer/core'
import { IconInfo } from '@veneer/core'
import {typography, layout} from '@veneer/tokens/dist/tokens'// Import the specific token 'typography' from '@veneer/tokens'


export const donutSquareSymbol = styled.div`
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 25%;
  background-color: ${(props) => props.Color};
`

export const donutCategoryItem = styled.div`
  font-family: ${typography.family0};
  font-size: ${typography.size1};
  line-height: ${typography.lineHeight2};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-bottom: 12px;
`

export const overviewlegendlistitem = styled(ListItem)`
  margin: -12px;
  @media only screen and (min-width: 700px) {
    white-space: nowrap;
  }
`

export const donutListItemCenterArea = styled.div`
  font-size: ${typography.size0};
  font-family: ${typography.family0};
  line-height: ${typography.lineHeight1};
`

export const donutListItemCenterArea_2 = styled.div`
  font-size: ${typography.size0};
  font-family: ${typography.family0};
  line-height: ${typography.lineHeight1};
`

export const donutOverviewContainer = styled.div`
  padding-right: 3px;
  height: 90%;
  display: flex;
  flex: 1 1 0;
`

export const donutdHeader1 = styled.div`
  padding: ${layout.size3};
  flex-grow: 1;
`
export const donutdHeader2 = styled.div`
  width: 100%;
  text-align: center;
` 
export const donutHeaderAssessmentSatatus = styled.p`
 @media only screen and (max-width: 400px) and (min-width: 350px) {
    display: flex;
    flex-wrap: wrap;
  }
`
export const donutHeader3 = styled.div`
@media only screen and (max-width: 349px) and (min-width: 250px) {
    padding-left: 0px;
  }
@media only screen and (max-width: 400px) and (min-width: 350px) {
    padding-left: 10px;
  }
  display: flex;
  flex-direction: row;
  font-size: 20px;
  line-height: 24px;
  width: 382.5px;
  height: 24px; /* Consider removing or adjusting this for responsiveness */
  text-align: center;
  padding-right: ${layout.size3};
  padding-left: 40px;
  padding-bottom: 29px;
  margin-top: 23px;
  color: #2b2b2b;
  #tooltip-default {
    text-align: left;
  }
`

export const donutHeader4 = styled.div`
 @media only screen and (max-width: 400px) and (min-width: 300px) {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    padding-left: 50px;
  }
  @media only screen and (max-width: 600px) and (min-width: 401px){
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: center; /* Add this line to align items in center vertically */x
  }
  width: 100%;
  display: flex;
  align-self: stretch;
`;

export const donutOverViewGirdItem = styled.div`
  flex: 1 1 0;
  padding-left: ${layout.size3};
`

export const donutOverViewGirdItem_2 = styled.div`
  flex: 1 1 0;
  padding-top: 29px;
  padding-left: 5px;
  width: fit-content;
`

export const overviewDonutsize = styled.div`
  @media only screen and (min-width: 700px) {
    width: 200px;
    height: 200px;
  }
  @media only screen and (max-width: 699px) {
    width: 200px;
    height: 200px;
  }
`

export const donutList = styled.div`
  margin-bottom: ${layout.size3};
`

export const IconInfoShow = styled(IconInfo)`
  position: relative;
  top: 1px;
  left: 6px;
`
