export function buildDate({
  year,
  month,
  day,
}) {
  let monthStr = `${month}`;
  let dayStr = `${day}`;

  if (month < 10) {
    monthStr = `0${month}`;
  }

  if (day < 10) {
    dayStr = `0${day}`;
  }

  return `${year}-${monthStr}-${dayStr}`;
}

export function getEndDate(selectedYear, currentDate) {
  if (selectedYear === currentDate.getFullYear()) {
    return currentDate.toISOString().split('T')[0];
  }

  return `${selectedYear}-12-31`;
}

export const SQCM_TO_SQFT = 0.00107639;
export const SQCM_TO_SQM = 0.0001;

export const STORAGE_COLUMN_DATA_USAGE = 'dashboard-column-detail-data-usage';

export const generalTooltip = {
  useHTML: true,
  backgroundColor: '#2B2B2B',
  style: {
    color: '#FFF',
  },
  borderRadius: 12,
  padding: 8,
  fontSize: 16,
  cursor: 'pointer',
};

export const getTooltipAverage = point => {
  const { selectedYear, name } = point.series.userOptions;

  return `<b>${selectedYear}</b> <br/> ${name}: ${point.y}`;
};

export const getTooltipGeneralAverage = (pointsAverage, isDeviceSupportTouch) => {
  let textTooltip = '';
  if (isDeviceSupportTouch && pointsAverage && pointsAverage.length > 0) {
    textTooltip = `${getTooltipAverage(pointsAverage[0])}<br/><br/>`;
  }
  return textTooltip;
};

export const getPointsWithoutAverage = points => points.filter(p => p.series.userOptions?.isTooltipAverage !== true);
export const getPointsAverage = points => points.filter(p => p.series.userOptions?.isTooltipAverage === true);
export const getPointSelected = points => points?.filter(p => p.series.state === 'hover')[0];

export const buildTooltipTitleMapping = (xAxisCategories, tooltipCategories) => tooltipCategories
  .reduce((acc, cur, i) => ({
    ...acc,
    [xAxisCategories[i]]: cur,
  }), {});

export const getYearlyTotalRange = ({ firstUsageDate, currentDate }) => {
  if (!firstUsageDate) {
    return 1;
  }

  return currentDate.getUTCFullYear() - firstUsageDate.getUTCFullYear() + 1;
};
