import { DeviceCacheApiClient } from '@jarvis/web-stratus-client'

export class DevicesClient {
  stack
  baseUrl
  authProvider

  constructor(stack, baseUrl, authProvider) {
    this.stack = stack
    this.baseUrl = baseUrl + '/v1'
    this.authProvider = authProvider
  }

  exportDeviceCountApi = async (connnectionState = '') => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.count({
      url: `/devices?${connnectionState}serviceId=ws-hp.com/smcloud`
    })
    const totalCount = response?.headers['x-total-count']
    return totalCount
  }

  exportDevicesApi = async (deviceOptions) => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.list({
      params: deviceOptions
    })
    const records = response?.data
    return records
  }

  isStatusCodeOk(status) {
    return status > 199 && status < 400
  }
}
