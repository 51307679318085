import { useState } from 'react'
import { DashboardClient } from '../client/DashboardClient'
import { getEnvConfig } from '../utils/consts'

export const useStatus = (stack) => {
  const [isStatusFetching, setIsStatusFetching] = useState(false)
  const [errorStatus, setErrorStatus] = useState(false)
  const config = getEnvConfig(stack)

  const fetchCurrentStatusSummary = async () => {
    const dashboardClient = new DashboardClient(config.SSApiUrl, '0d')
    try {
      setIsStatusFetching(true)
      return await dashboardClient.getAssessmentSummary()
    } catch (e) {
      setIsStatusFetching(false)
      setErrorStatus(e)
      console.log(e)
    }
  }

  return {
    fetchCurrentStatusSummary,
    isStatusFetching,
    errorStatus
  }
}
