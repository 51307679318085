import React, { useEffect, useState } from 'react'
import { SecurityAssessmentActivity } from '../SecurityAssessmentActivity'
import { SecurityAssessmentStatus } from '../SecurityAssessmentStatus'
import { SecurityAssessmentSummary } from '../SecurityAssessmentSummary'
import {typography, layout} from '@veneer/tokens/dist/tokens'
import { useTrends } from '../../hooks/useTrends'
import { useAssessmentSummary } from '../../hooks/useAssessmentSummary'
import { useStatus } from '../../hooks/useStatus'
import { useDevices } from '../../hooks/useDevices'
import { setProps } from '../../utils/commonMethods'
import * as Wrapper from './Styles'
import {
  STATUS_CARD_SCOPES,
  SUMMARY_CARD_SCOPES,
  TREND_CARD_SCOPES,
  Colors
} from '../../utils/consts'
import { RootProvider } from '@jarvis/react-portal-addons'
import resources from '../../assets/locale/index'

const assessedColors = [
  Colors.green1,
  Colors.red1,
  Colors.orange,
  Colors.yellow
]
const notAssessedColors = [Colors.grey, Colors.red1]
const colors = [
  Colors.grey,
  Colors.green2,
  Colors.red1,
  Colors.green1,
  Colors.orange,
  Colors.yellow
]
const styles = [
  typography.family0,
  typography.family0,
  typography.size0,
  typography.size1,
  typography.size2,
  typography.size4,
  typography.size5,
  typography.size7,
  layout.size2,
  layout.size3,
  layout.size4,
  layout.size5,
  layout.size6,
  typography.lineHeight2,
  typography.lineHeight3,
  typography.lineHeight5,
  typography.lineHeight7,
  typography.lineHeight0
]
const componentProps = {
  assessedColors,
  notAssessedColors,
  colors,
  styles
}

export const EndpointSecurityDashboard = (props) => {
  setProps(props)
  const { accessControl } = props

  const [time, setTime] = useState('1d')
  const { devicesCount, error } = useDevices(props.stack)
  const [summaryData, setSummaryData] = useState([])
  const [activityData, setActivityData] = useState([])
  const [dataFor0dTrends, setDataFor0dTrends] = useState([])
  const [dataFor0dStatus, setDataFor0dStatus] = useState([])
  const [dataFor0dSummary, setDataFor0dSummary] = useState([])
  const [showTrendsCard, setShowTrendsCard] = useState(false)
  const [showStatusCard, setShowStatusCard] = useState(false)
  const [showSummaryCard, setShowSummaryCard] = useState(false)
  const [loading, setLoading] = useState(false)
  const { fetchTrendsSummary, fetchActivity } = useTrends(props.stack)
  const { fetchCurrentAssessmentSummary } = useAssessmentSummary(props.stack)
  const { fetchCurrentStatusSummary } = useStatus(props.stack)

  //const { setRefreshFlag } = props

  useEffect(() => {
    setLoading(true)

    accessControl &&
      accessControl.checkScopes(TREND_CARD_SCOPES).then((response) => {
        setShowTrendsCard(response)
      })

    accessControl &&
      accessControl.checkScopes(STATUS_CARD_SCOPES).then((response) => {
        setShowStatusCard(response)
      })

    accessControl &&
      accessControl.checkScopes(SUMMARY_CARD_SCOPES).then((response) => {
        setShowSummaryCard(response)
      })
  }, [])

  useEffect(() => {
    showTrendsCard &&
      fetchTrendsSummary(time).then((Response1) => {
        setSummaryData([Response1])
      })
    showTrendsCard &&
      fetchActivity(time).then((Response2) => {
        setActivityData([Response2])
      })
  }, [time, showTrendsCard])

  useEffect(() => {
    showTrendsCard &&
      fetchTrendsSummary('0d').then((Response) => {
        setDataFor0dTrends([Response])
        setLoading(false)
      })
    showStatusCard &&
      fetchCurrentStatusSummary().then((Response) => {
        setDataFor0dStatus([Response])
        setLoading(false)
      })
    showSummaryCard &&
      fetchCurrentAssessmentSummary().then((Response) => {
        setDataFor0dSummary([Response])
        setLoading(false)
      })
  }, [showTrendsCard, showStatusCard, showSummaryCard])

  return (
    <>
      <RootProvider localization={props.localization} resources={resources}>
        {loading ? (
          <Wrapper.LoaderProgress />
        ) : (
          <Wrapper.DashBoardoverviewsamplesection data-testid="overview-sample-section">
            <Wrapper.overviewmain>
              {console.log(
                'Scopes: ',
                showStatusCard,
                showSummaryCard,
                showTrendsCard
              )}
              {showTrendsCard && (
                <Wrapper.overviewActivity data-testid="overview-Activity">
                  <SecurityAssessmentActivity
                    summaryData={summaryData}
                    activityData={activityData}
                    setDataFor0d={dataFor0dTrends}
                    setTime={(days) => {
                      setTime(days)
                    }}
                    {...componentProps}
                  />
                </Wrapper.overviewActivity>
              )}
              {showStatusCard && (
                <Wrapper.overviewStatus data-testid="overview-Status">
                  <SecurityAssessmentStatus
                    currentSummaryData={dataFor0dStatus}
                    devicesCount={devicesCount}
                    navigation={props.navigation}
                    error={error}
                    {...componentProps}
                  />
                </Wrapper.overviewStatus>
              )}
            </Wrapper.overviewmain>
            {showSummaryCard && (
              <Wrapper.overviewSummary data-testid="overview-Summary">
                <SecurityAssessmentSummary
                  currentSummaryData={dataFor0dSummary}
                  {...componentProps}
                />
              </Wrapper.overviewSummary>
            )}
          </Wrapper.DashBoardoverviewsamplesection>
        )}
      </RootProvider>
    </>
  )
}
