import React, { useEffect, useState } from 'react'
import { Card } from '@veneer/core'
import { ProgressIndicator } from '@veneer/core'
import Header from '../Header'
import Donut from './../Donut'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './Styles'

export const SecurityAssessmentSummary = (props) => {
  const { t } = useI18n()
  const { assessedColors, notAssessedColors } = props
  const [data, setData] = useState<any>([])
  useEffect(() => {
    if (props.currentSummaryData[0]) setData(props.currentSummaryData[0])
  }, [props])

  const compliant = parseInt(data.compliant, 10)
  const highRisk = parseInt(data.nonCompliant?.highRisk, 10)
  const mediumRisk = parseInt(data.nonCompliant?.mediumRisk, 10)
  const lowRisk = parseInt(data.nonCompliant?.lowRisk, 10)
  const assessed = compliant + highRisk + mediumRisk + lowRisk
  const total = parseInt(data.total, 10)
  const disconnected = parseInt(data.notAssessed?.disconnected, 10)
  const unknown = parseInt(data.notAssessed?.unknown, 10)
  // const notSupported = total - assessed - disconnected - unknown
  const error = disconnected
  const nonComplaintDevices = total - assessed

  const DonutChartAssessed = [
    {
      Title: t(
        'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.header',
        'Assessed Status By Devices'
      ), // optional if empty string then it will not be displayed
      IconShow: true,
      Tooltiptext: t(
        'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.tooltipText',
        'An assessment status of passed means that the device is compliant with the policy(s) used during the assessment. High, Medium and Low risk means that the device is deviating from the policy.'
      ),
      DonutDiplayText: assessed,
      DonutDiplaySubText:
        t(
          'ecp-endpointsecurity-dashboard.assessmentSummary.donut.text1',
          'of'
        ) +
        ' ' +
        total +
        ' ' +
        t(
          'ecp-endpointsecurity-dashboard.assessmentSummary.donut.text2',
          'Devices'
        ),
      TotalCount: total,
      CategoryList: [
        {
          CategoryTitle: t(
            'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.totalDevices',
            'TOTAL DEVICES'
          ),
          CategoryCount: assessed,
          DisplayCount: true,
          data: [
            {
              Item: 'compliant',
              ItemDisplayText: t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.passedStatus',
                'Passed'
              ),
              SubItem: [],
              Count: data.compliant,
              Color: assessedColors[0]
            },
            {
              Item: 'highRisk',
              ItemDisplayText: t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.highStatus',
                'High Risk'
              ),
              SubItem: [],
              Count: data.nonCompliant?.highRisk,
              Color: assessedColors[1]
            },
            {
              Item: 'mediumRisk',
              ItemDisplayText: t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.mediumStatus',
                'Medium Risk'
              ),
              SubItem: [],
              Count: data.nonCompliant?.mediumRisk,
              Color: assessedColors[2]
            },
            {
              Item: 'lowRisk',
              ItemDisplayText: t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.lowStatus',
                'Low Risk'
              ),
              SubItem: [],
              Count: data.nonCompliant?.lowRisk,
              Color: assessedColors[3]
            }
          ]
        }
      ]
    }
  ]
  const DonutChartNotAssessed = [
    {
      Title: t(
        'ecp-endpointsecurity-dashboard.assessmentSummary.notAssessed.header',
        'Not Assessed Status By Devices'
      ), // optional if empty string then it will not be displayed
      IconShow: false,
      Tooltiptext: t(
        'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.tooltipText',
        'An assessment status of passed means that the device is compliant with the policy(s) used during the assessment. High, Medium and Low risk means that the device is deviating from the policy.'
      ),
      DonutDiplayText: nonComplaintDevices,
      DonutDiplaySubText:
        t(
          'ecp-endpointsecurity-dashboard.assessmentSummary.donut.text1',
          'of'
        ) +
        ' ' +
        total +
        ' ' +
        t(
          'ecp-endpointsecurity-dashboard.assessmentSummary.donut.text2',
          'Devices'
        ),
      TotalCount: total,
      CategoryList: [
        {
          CategoryTitle: t(
            'ecp-endpointsecurity-dashboard.assessmentSummary.assessed.totalDevices',
            'TOTAL DEVICES'
          ),
          CategoryCount: nonComplaintDevices,
          DisplayCount: true,
          data: [
            {
              Item: 'notAssessed',
              ItemDisplayText: t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.notAssessed.status1.header',
                'Error'
              ),
              SubItem: [
                {
                  SubItemName: 'disconnected',
                  ItemDisplayText: t(
                    'ecp-endpointsecurity-dashboard.assessmentSummary.notAssessed.status1.subStatus1',
                    'Disconnected'
                  ),
                  SubItemCount: data.notAssessed?.disconnected
                }
              ],
              Count: error,
              Color: notAssessedColors[1]
            },
            {
              Item: 'unknown',
              ItemDisplayText: t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.notAssessed.status2.header',
                'Unknown'
              ),
              Count: unknown,
              SubItem: [],
              Color: notAssessedColors[0]
            }
          ]
        }
      ]
    }
  ]

  const assessmentSummary = () => {
    return (
      <div className="dashboard_security_summary">
        <Header
          data-testid="overview-header-section"
          LeftArea={
            <Wrapper.dashboardSecuritySummary>
              {t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.header',
                'Assessment Summary'
              )}
            </Wrapper.dashboardSecuritySummary>
          }
          RightArea={''}
        />
        <Wrapper.dashboardOverviewDonutSummary>
          <Donut data-testid="overview-donut-chart" data={DonutChartAssessed} />

          <Wrapper.overviewDonutDivider />

          <Donut
            css={{ paddingRight: '55px' }}
            data-testid="overview-donut-chart"
            data={DonutChartNotAssessed}
          />
        </Wrapper.dashboardOverviewDonutSummary>
      </div>
    )
  }
  const blankCard = () => {
    return (
      <Wrapper.dashboardSecuritySummaryBlankCard>
        <Header
          data-testid="overview-header-section"
          LeftArea={
            <Wrapper.dashboardHeder>
              {t(
                'ecp-endpointsecurity-dashboard.assessmentSummary.header',
                'Assessment Summary'
              )}
            </Wrapper.dashboardHeder>
          }
          RightArea={''}
        />

        <Wrapper.dashboardOverviewNoInformation data-testid="overview-no-information">
          <ProgressIndicator />
        </Wrapper.dashboardOverviewNoInformation>
      </Wrapper.dashboardSecuritySummaryBlankCard>
    )
  }

  if (Object.keys(data).length >= 1) {
    return (
      <Wrapper.assessmentSummaryCard>
      <Card
        data-testid="overview-card"
        content={assessmentSummary() as any}
        background="filled"
        border="dropShadow"
      />
      </Wrapper.assessmentSummaryCard>
    )
  } else {
    return (
      <Wrapper.assessmentSummaryCard>
      <Card
        data-testid="overview-card"
        content={blankCard() as any}
        background="filled"
        border="dropShadow"
      />
      </Wrapper.assessmentSummaryCard>
    )
  }
}
