import { ProgressIndicator } from '@veneer/core'
import styled from 'styled-components'
//import tokens from '@veneer/tokens';

export const DashBoardoverviewsamplesection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 570px;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 6px;
  padding-right: 6px;
`

export const overviewmain = styled.div`
  display: flex;
  height: 400px;
  @media only screen and (max-width: 1090px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 2%;
    flex: 100%;
  }
  @media only screen and (min-width: 1091px) {
    display: flex;
    width: 100%;
    margin-bottom: 2%;
    flex: 100%;
  }
`

export const overviewActivity = styled.div`
  // height: 100%;
  @media only screen and (max-width: 1090px) {
    width: 100%;
    margin-bottom: 2%;
  }
  @media only screen and (min-width: 1091px) {
    margin-right: 2%;
    width: 68%;
  }
`

export const overviewStatus = styled.div`
  @media only screen and (max-width: 1090px) {
    width: 100%;
  }
  @media only screen and (min-width: 1091px) {
    width: 30%;
  }
`

export const overviewSummary = styled.div`
  @media only screen and (max-width: 1090px) {
    width: 100%;
    flex: 100%;
  }
  @media only screen and (min-width: 1091px) {
    width: 100%;
    flex: 100%;
  }
`

export const LoaderProgress = styled(ProgressIndicator)`
  position: relative;
  top: 10px;
  left: 50%;
`
