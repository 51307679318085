import styled from 'styled-components'
import { layout } from '@veneer/tokens/dist/tokens'
import { Colors } from '../../utils/consts'
import { ListItem } from '@veneer/core'

export const SelectWrapper = styled.div`
@media only screen and (max-width: 400px) {
    width: 100px;
    margin-left: 20px;
  }
  width: initial;
  color: ${Colors.black2};
  flex-grow: 0;
  width: 160px;
  box-sizing: border-box; 
  & * {
    box-sizing: border-box;
    padding: 0 !important;
    min-height: none;
    height: 100%;
    max-height: 100%;
  }
  & > div {
    & > div {
      padding: ${layout.size2} ${layout.size2} !important;
      line-height: 1.1;
    }
  }
`

export const DashBoardCheckRisk = styled.div`
  color: ${(props) => props?.Colors[1]};
  font-size: ${(props) => props?.Styles[4]};
  font-family: ${(props) => props?.Styles[0]};
  display: flex;
  margin-top: 6px;
`

export const DashBoardCheckRiskstyle1 = styled.span`
  margin-right: 8px;
  height: 20px;
`

export const DashBoardCheckRiskstyle2 = styled.div`
  margin-top: -2px;
  width: 12.31px;
  height: 17.03px;
`

export const DashBoardpercentValue = styled.div`
  color: ${(props) => props?.Colors[2]};
  font-size: ${(props) => props?.Styles[4]};
  font-family: ${(props) => props?.Styles[0]};
  display: flex;
  margin-top: 6px;
`

export const DashBoardpercentValueStyle1 = styled.span`
  margin-right: 8px;
  height: 20px;
`

export const DashBoardpercentValueStyle2 = styled.div`
  margin-top: -2px;
  width: 12.31px;
  height: 17.03px;
`

export const DashBoardOverviewMenuCircular1 = styled.span`
  @media only screen and (max-width: 650px) {
    margin-left: 20px;
  }
  width: 16px;
  height: 16px;
  border-radius: 20px;
  display: inline-block;
  background: ${Colors.red1};
`

export const DashBoardOverviewMenuCircular2 = styled.span`
  @media only screen and (max-width: 650px) {
    margin-left: 20px;
  }
  width: 16px;
  height: 16px;
  border-radius: 20px;
  display: inline-block;
  background: ${Colors.orange};
`

export const DashBoardOverviewMenuCircular3 = styled.span`
  @media only screen and (max-width: 650px) {
    margin-left: 20px;
  }
  width: 16px;
  height: 16px;
  border-radius: 20px;
  display: inline-block;
  background: ${Colors.yellow};
`

export const DashBoardOverviewMenuCircular4 = styled.span`
  @media only screen and (max-width: 650px) {
    margin-left: 20px;
  }
  width: 16px;
  height: 16px;
  border-radius: 20px;
  display: inline-block;
  background: ${Colors.purple};
`

export const dashboardendpointSecurityactivitygrid = styled.div`
  display: flex;
  flex-direction: row;
`
export const DashboardListItem = styled(ListItem)`
  margin-top: -10px;
`

export const dashboardpercentValue3 = styled.div`
  color: ${(props) => props?.Colors[0]};
  font-size: ${(props) => props?.Styles[4]};
  display: flex;
  font-family: ${(props) => props?.Styles[0]};
  margin-top: 6px;
  margin-right: -7px;
`

export const DashBoardPercentvalueElse = styled.span`
  margin-right: 13px;
  height: 20px;
`

export const DashBoardPercentvalueElse_1 = styled.div`
  width: 14px;
  height: 2px;
`

export const DashboardEndpointSecurityActivityGrid = styled.div`
  @media only screen and (max-width: 570px) and (min-width: 300px) {
    display: flex;
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  padding: 5px 24px 26.5px 24px;
  margin-top: 19px;
`

export const DashboardDonutdividerOverview = styled.div`
  border-right: 3px solid #ebe7e7;
  margin: 0px 24px 0px 24px;
  @media only screen and (max-width: 570px) and (min-width: 300px) {
    border-bottom: 3px solid #ebe7e7;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const DashboarddashboardEndpointSecurityActivityBlankCard = styled.div`
  height: 348px;
`

export const DashboarddashboardProgressIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
export const assessmentActivityCard = styled.div`
  width: 100%;
  transition: width 0.5s ease; /* Add transition property */
  @media only screen and (max-width: 350px) and (min-width: 200px){
    width: 52%;
  }
   @media only screen and (max-width: 400px) and (min-width: 351px){
    width: 57%;
  }
   @media only screen and (max-width: 450px) and (min-width: 401px){
    width: 65%;
  }
  @media only screen and (max-width: 500px) and (min-width: 451px){
    width: 75%;
  }
  @media only screen and (max-width: 557px) and (min-width: 501px){
    width: 85%;
  }
  @media only screen and (max-width: 600px) and (min-width: 558px){
    width: 95%;
  }
`