import React from 'react';

const IconShieldArrowMinus = () => {
  return <svg width="39px" height="39px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>Shield_Minus_Light</title>
    <desc>Created with Sketch.</desc>
    <g id="Shield_Minus_Light" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(4.000000, 2.000000)">
        <path d="M15.99256,10.1752 L15.99256,4.093384 C15.99256,3.583008 15.61,3.134336 15.10072,3.099424 C12.7348,2.937136 10.44648,2.004392 8.63032,0.437376 C8.44936,0.281192 8.2228,0.203128 7.99672,0.203128 C7.7704,0.203128 7.54432,0.281192 7.3632,0.437376 C5.54704,2.004392 3.258704,2.937136 0.892736,3.099424 C0.383456,3.134336 0.000888,3.583008 0.000888,4.093384 L0.000888,10.1752 C0.000888,11.248 -0.055992,12.34664 0.247224,13.38744 C0.788,15.2452 2.127112,16.64664 3.663,17.75008 C4.90032,18.61264 6.25288,19.28464 7.67712,19.74584 C7.7804,19.77928 7.88856,19.796 7.99672,19.796 C8.10488,19.796 8.21304,19.77928 8.31632,19.74584 C9.74064,19.28464 11.0932,18.61264 12.33048,17.75008 C13.8664,16.64664 15.20544,15.2452 15.74624,13.38744 C16.04944,12.34664 15.99256,11.248 15.99256,10.1752 Z M14.78632,13.10792 C14.37832,14.50896 13.38416,15.76176 11.75872,16.92968 C10.6056,17.73344 9.3476,18.35928 7.99672,18.79032 C7.9928,18.7916 7.98896,18.79312 7.98504,18.7944 C6.65296,18.3632 5.39128,17.73576 4.24648,16.93792 C2.609288,15.76176 1.615392,14.50896 1.207192,13.10792 C0.986488,12.34936 0.992344,11.5104 0.998936,10.62216 L1.001136,10.1752 L0.961096,4.097104 C3.53824,3.920288 6.03584,2.895752 7.99672,1.211544 C9.95768,2.895752 12.45496,3.920288 14.99256,4.09332 L14.99256,10.1752 L14.9948,10.62216 C15.00112,11.5104 15.00696,12.34936 14.78632,13.10792 Z" id="Shape" fill="#212121" fillRule="nonzero"></path>
        <path d="M4,9 L12,9 C12.5522847,9 13,9.44771525 13,10 C13,10.5522847 12.5522847,11 12,11 L4,11 C3.44771525,11 3,10.5522847 3,10 C3,9.44771525 3.44771525,9 4,9 Z" id="Rectangle" fill="#737373"></path>
      </g>
    </g>
  </svg>
};

export default IconShieldArrowMinus;
