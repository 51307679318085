import styled from 'styled-components'
import { ListItem } from '@veneer/core'
import { Colors } from '../../utils/consts'

export const dashboardSecurityStatus = styled.div`
  align-items: center;
  display: flex;
`

export const dashboardSecurityStatusContent = styled.div`
  margin-top: -3px;
`

export const dashboardOverviewNavigation = styled.div`
  & :hover {
    cursor: pointer;
  }
  font-size: 16px;
  line-height: 20px;
`

export const dashboardOverviewStatusListItem_0 = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding-right: 8px;
  // width: 105px;
  min-height: 20px;
  height: auto;
  margin-top: 5.5px;
  display: flex;
  flex-wrap: wrap;
`

export const dashboardTrailingArea_1 = styled.div`
  display: flex;
`

export const dashboardTrailingArea_2 = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.black2};
`

export const dashboardTrailingArea_3 = styled.div`
  margin-top: 2px;
`

export const dashboardLeadingArea = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background: ${Colors.green2};
  margin-top: 2px;
`

export const dashboardcenterArea = styled.div`
  font-size: 16px;
  line-height: 20px;
  // width:43px;
  height: 20px;
  color: ${Colors.black1};
  margin-top: 3px;
  white-space: nowrap;
`

export const dashboardTrailingArea_4 = styled.div`
  padding-right: 24px;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.black2};
`

export const dashboardTrailingArea_5 = styled.div`
  padding-right: 24px;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.black2};
`

export const dashboardTrailingArea_6 = styled.div`
  font-size: 20px;
  line-height: 24px;
  padding-right: 22px;
  color: ${Colors.green2};
`

export const dashboardTrailingArea_7 = styled.div`
  font-size: 20px;
  line-height: 24px;
  padding-right: 22px;
  color: ${Colors.red2};
`

export const dashboardcenterArea_2 = styled.div`
  font-size: 16px;
  line-height: 20px;
  // width: 44px;
  height: 20px;
  color: ${Colors.black1};
  margin-top: 2.5px;
  white-space: nowrap;
`

export const dashboardLeadingArea_2 = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background: ${Colors.grey};
  margin-top: 2px;
`

export const dashboardLeadingArea_3 = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding-right: 8px;
  // width: 121px;
  height: auto;
  min-height: 20px;
  color: ${Colors.black1};
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;
`

export const dashboardLeadingArea_4 = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding-right: 8px;
  // width: 153px;
  height: 20px;
  color: ${Colors.black1};
  display: flex;
  flex-wrap: wrap;
`

export const dashboardOverviewDivider = styled.hr`
  border-radius: 2px;
  padding: 8px, 0;
  height: 1px;
  border-style: solid;
  opacity: 0.1;
  color: #646060;
  background-color: #646060;
`

export const dashboardSecurityStatusCard = styled.div`
  height: 360px;
`

export const dashboardOverviewHeaderSection_LeftArea = styled.div`
  align-items: center;
  display: flex;
`

export const dashboardOverviewNoDevices = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
export const listItemComp = styled(ListItem)`
  border-radius: 0px !important;
  padding: 12px 36px !important;
  border-top: 1px solid #e4e2e2 !important;
  &:hover {
    background-color: #f7f7f7 !important;
  }
`
export const listItemComp1 = styled(ListItem)`
  border-top: 1px solid #e4e2e2 !important;
  border-radius: 0px !important;
`
export const assessmentStatusCard = styled.div`
  width: 100%;
  transition: width 0.5s ease; /* Add transition property */
   @media only screen and (max-width: 350px) and (min-width: 200px){
    width: 52%;
  }
   @media only screen and (max-width: 400px) and (min-width: 351px){
    width: 57%;
  }
   @media only screen and (max-width: 450px) and (min-width: 401px){
    width: 65%;
  }
  @media only screen and (max-width: 500px) and (min-width: 451px){
    width: 75%;
  }
  @media only screen and (max-width: 557px) and (min-width: 501px){
    width: 85%;
  }
  @media only screen and (max-width: 600px) and (min-width: 558px){
    width: 95%;
  }
`