import React from 'react'
import { EndpointSecurityDashboard } from '../src/components/EndpointSecurityDashboard'
import { ShellProps } from './types/shell'
import packageInfo from '../package.json'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const { stack } = props
  const { navigation } = props
  const namespace = '@jarvis/react-ecp-endpointsecurity-dashboard'

  return (
    <section id={namespace}>
      <EndpointSecurityDashboard
        id={packageInfo.name}
        {...props}
        authProvider={v1.authProvider}
        country={props.country}
        search={props.search || true}
        export={props.export || true}
        language={props.language}
        allDashboard={props.allDashboard || true}
        stack={stack}
        navigation={navigation}
      />
    </section>
  )
}
