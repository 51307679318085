import { useState } from 'react'
import { DashboardClient } from '../client/DashboardClient'
import { getEnvConfig } from '../utils/consts'

export const useAssessmentSummary = (stack) => {
  const [isAssessmentFetching, setIsAssessmentFetching] = useState(false)
  const [errorAssessment, setErrorAssessment] = useState(false)
  const config = getEnvConfig(stack)

  const fetchCurrentAssessmentSummary = async () => {
    const dashboardClient = new DashboardClient(config.SSApiUrl, '0d')
    try {
      setIsAssessmentFetching(true)
      return await dashboardClient.getAssessmentSummary()
    } catch (e) {
      setErrorAssessment(e)
      console.log(e)
    }
  }

  return {
    fetchCurrentAssessmentSummary,
    isAssessmentFetching,
    errorAssessment
  }
}
