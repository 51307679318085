import React from 'react'
import { List, Tooltip } from '@veneer/core'
import Chart from '../Chart'
import highchartsInterface, {
  ChartProvider
} from '@veneer/highcharts-interface'
import * as Wrapper from './Styles'
import { useI18n } from '@jarvis/react-portal-addons'
import { Colors } from '../../utils/consts'

function countTotalOfCategories(categories) {
  let total = 0
  for (let i = 0; i < categories.length; i++) {
    total += categories[i].CategoryCount
  }
  return total
}

const Donut = (props) => {
  const { data } = props
  const { t } = useI18n()
  const header = data[0]?.Title
  const tooltipText = data[0]?.Tooltiptext
  const iconShow = data[0]?.IconShow
  const donutText = data[0]?.DonutDiplayText
  const donuSubText = data[0]?.DonutDiplaySubText
  const categories = data[0]?.CategoryList
  const dataForDonut = []
  const totalCountOfAllCategories = countTotalOfCategories(categories)

  const donutColors = []
  const getpercent = (itemTotal) => {
    const percentage = Math.floor((itemTotal * 100) / totalCountOfAllCategories)
    if (totalCountOfAllCategories === 0) return '0.00'
    return percentage.toFixed(2)
  }
  const Square = (color) => {
    return <Wrapper.donutSquareSymbol Color={color} />
  }

  const modified = []
  for (let i = 0; i < categories.length; i++) {
    let item
    const categoryItem = (
      <Wrapper.donutCategoryItem>
        {categories[i]?.DisplayCount
          ? categories[i]?.CategoryTitle + ': ' + categories[i]?.CategoryCount
          : categories[i]?.CategoryTitle}
      </Wrapper.donutCategoryItem>
    )
    modified.push(categoryItem)
    for (let j = 0; j < categories[i].data.length; j++) {
      const dataItem = categories[i]?.data[j]
      const itemTotal = parseInt(dataItem.Count, 10)
      dataForDonut.push([dataItem?.ItemDisplayText, itemTotal])
      donutColors.push(dataItem?.Color)
      item = (
        <List>
          <Wrapper.overviewlegendlistitem
            leadingArea={Square(dataItem.Color)}
            centerArea={
              <Wrapper.donutListItemCenterArea>
                {dataItem?.ItemDisplayText +
                  ': ' +
                  getpercent(itemTotal) +
                  '% (' +
                  itemTotal +
                  ')'}
              </Wrapper.donutListItemCenterArea>
            }
            trailingArea={false}
          />
        </List>
      )

      modified.push(item)
      for (let k = 0; k < dataItem.SubItem.length; k++) {
        const subItem = dataItem?.SubItem[k]
        const subItemCount = parseInt(subItem?.SubItemCount, 10)
        const subItemModified = (
          <List>
            <Wrapper.overviewlegendlistitem
              leadingArea={Square('transparent')}
              centerArea={
                <Wrapper.donutListItemCenterArea_2>
                  {subItem?.ItemDisplayText + ' (' + subItemCount + ')'}
                </Wrapper.donutListItemCenterArea_2>
              }
              trailingArea={false}
            />
          </List>
        )
        modified.push(subItemModified)
      }
    }
    const space = (
      <div
        style={
          {
            // paddingBottom: styles[10]
          }
        }
      />
    )
    modified.push(space)
  }
  return (
    <Wrapper.donutOverviewContainer data-testid="overview-Container">
      <Wrapper.donutdHeader1>
        {header !== '' ? (
          <Wrapper.donutdHeader2>
            <Wrapper.donutHeader3>
              <Wrapper.donutHeaderAssessmentSatatus>
              {header}{' '}
              </Wrapper.donutHeaderAssessmentSatatus>
              {iconShow ? (
                <Tooltip arrow content={tooltipText} id="tooltip-default">
                  <Wrapper.IconInfoShow />
                </Tooltip>
              ) : (
                ''
              )}
            </Wrapper.donutHeader3>
          </Wrapper.donutdHeader2>
        ) : (
          false
        )}
        <Wrapper.donutHeader4>
          <Wrapper.donutOverViewGirdItem>
            <Wrapper.overviewDonutsize data-testid="overview-donut-chart">
              <ChartProvider chartInterface={highchartsInterface}>
                {totalCountOfAllCategories && donutText > 0 ? (
                  <Chart
                    options={{
                      chart: {
                        type: 'donut',
                        height: '100%'
                      },
                      legend: {
                        layout: 'vertical'
                      },
                      plotOptions: {
                        pie: {
                          showInLegend: true,
                          innerSize: '85%',
                          size: '100%',
                          colors: [...donutColors],
                          states: {
                            inactive: {
                              opacity: 0.2
                            }
                          }
                        }
                      },
                      title: {
                        text: donutText,
                        style: {
                          fontSize: '40px',
                          lineHeight: '48px',
                          width: 64,
                          height: 48
                        }
                      },
                      subtitle: {
                        text: donuSubText,
                        style: {
                          fontSize: '16px',
                          lineHeight: '24px',
                          width: 88,
                          height: 24
                        }
                      },
                      series: [
                        {
                          type: 'pie',
                          name: data[0]?.Title,
                          data: dataForDonut
                        }
                      ]
                    }}
                    type={undefined}
                  />
                ) : (
                  <Chart
                    options={{
                      chart: {
                        type: 'donut',
                        height: '100%'
                      },
                      tooltip: {
                        formatter: () => false
                      },
                      legend: {
                        layout: 'vertical'
                      },
                      plotOptions: {
                        pie: {
                          showInLegend: true,
                          innerSize: '85%',
                          size: '100%',
                          colors: [Colors.grey],
                          states: {
                            hover: {
                              brightness: 0,
                              halo: {
                                opacity: 0,
                                size: 0
                              }
                            },
                            inactive: {
                              opacity: 0.2
                            }
                          }
                        }
                      },
                      title: {
                        text: '0',
                        style: {
                          fontSize: '40px',
                          lineHeight: '48px',
                          width: 64,
                          height: 48
                        }
                      },
                      subtitle: {
                        text: donuSubText,
                        style: {
                          fontSize: '16px',
                          lineHeight: '24px',
                          width: 88,
                          height: 24
                        }
                      },
                      series: [
                        {
                          type: 'pie',
                          name: t(
                            'ecp-endpointsecurity-dashboard.assessmentSummary.donut.noDevice',
                            'No Device'
                          ),
                          data: [['Not Assessed', 1]]
                        }
                      ]
                    }}
                    type={undefined}
                  />
                )}
              </ChartProvider>
            </Wrapper.overviewDonutsize>
          </Wrapper.donutOverViewGirdItem>
          <Wrapper.donutOverViewGirdItem_2 data-testid="donut-legend">
            <List>
              <Wrapper.donutList>{modified}</Wrapper.donutList>
            </List>
          </Wrapper.donutOverViewGirdItem_2>
        </Wrapper.donutHeader4>
      </Wrapper.donutdHeader1>
    </Wrapper.donutOverviewContainer>
  )
}

export default Donut
