import { useState } from 'react'
import { DashboardClient } from '../client/DashboardClient'
import { getEnvConfig } from '../utils/consts'

export const useTrends = (stack) => {
  const config = getEnvConfig(stack)
  const [isSummaryFetching, setIsSummaryFetching] = useState(false)
  const [errorSummary, setErrorSummary] = useState(false)
  const [isActivityFetching, setIsActivityFetching] = useState(false)
  const [errorActivity, setErrorActivity] = useState(false)
  const fetchTrendsSummary = async (when) => {
    const dashboardClient = new DashboardClient(config.SSApiUrl, when)
    try {
      setIsSummaryFetching(true)
      return await dashboardClient.getAssessmentSummary()
    } catch (e) {
      setIsSummaryFetching(false)
      setErrorSummary(e)
      console.log(e)
    }
  }
  const fetchActivity = async (when) => {
    const dashboardClient = new DashboardClient(config.SSApiUrl, when)
    try {
      if (when) {
        setIsActivityFetching(true)
        return await dashboardClient.getAssessmentActivity()
      }
    } catch (e) {
      setIsActivityFetching(false)
      setErrorActivity(e)
      console.log(e)
    }
  }
  return {
    fetchTrendsSummary,
    fetchActivity,
    isSummaryFetching,
    isActivityFetching,
    errorSummary,
    errorActivity
  }
}
