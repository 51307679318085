import * as React from 'react'
import { ChartOptionInterface } from '@veneer/highcharts-interface'
import { ChartInterface } from '@veneer/highcharts-interface'
import { Chart as VeneerChart } from '@veneer/highcharts-interface'

interface ChartType extends Pick<ChartInterface, 'type'> {
  options: ChartOptionInterface & {
    subtitle?: object
    tooltip?: object
  }
}
export default function Chart({ type, options }: ChartType) {
  return (
    <VeneerChart data-testid="overview-Chart" type={type} options={options} />
  )
}
