import React, { useEffect, useState } from 'react'
import {
  Card,
  IconChevronRight,
  List,
  ListItem,
  ProgressIndicator
} from '@veneer/core'
import Header from '../Header'
import { useI18n } from '@jarvis/react-portal-addons'

import * as Wrapper from './Styles'

export const SecurityAssessmentStatus = (props) => {
  const { t } = useI18n()
  const { navigation, devicesCount } = props
  const [summaryValue, setSummaryValue] = useState<any>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (props.currentSummaryData[0])
      setSummaryValue(props.currentSummaryData[0])
    if (
      devicesCount?.onlineCount &&
      devicesCount?.offlineCount &&
      devicesCount?.totalCount
    ) {
      setTotal(devicesCount?.totalCount)
    } else {
      setTotal(0)
    }
  }, [props])

  const highRisk = parseInt(summaryValue.nonCompliant?.highRisk, 10)
  const mediumRisk = parseInt(summaryValue.nonCompliant?.mediumRisk, 10)
  const lowRisk = parseInt(summaryValue.nonCompliant?.lowRisk, 10)
  const noncomp = highRisk + mediumRisk + lowRisk
  const status = () => {
    return (
      <Wrapper.dashboardSecurityStatusCard>
        <Header
          data-testid="overview-header-section"
          LeftArea={
            <Wrapper.dashboardSecurityStatus>
              {t('ecp-endpointsecurity-dashboard.status.header', 'Status')}
            </Wrapper.dashboardSecurityStatus>
          }
          RightArea={''}
        />

        <Wrapper.dashboardSecurityStatusContent>
          <Wrapper.dashboardOverviewNavigation
            onClick={() => navigation.push('/solutions/security/devices')}
          >
            <Wrapper.listItemComp
              data-testid="overview-status-listItem-0"
              leadingArea={
                <Wrapper.dashboardOverviewStatusListItem_0>
                  {t(
                    'ecp-endpointsecurity-dashboard.status.deviceStatus.header',
                    'Entitled Devices'
                  )}
                </Wrapper.dashboardOverviewStatusListItem_0>
              }
              trailingArea={
                <Wrapper.dashboardTrailingArea_1>
                  <Wrapper.dashboardTrailingArea_2>
                    {devicesCount ? (
                      total
                    ) : props.error !== null ? (
                      0
                    ) : (
                      <ProgressIndicator />
                    )}
                  </Wrapper.dashboardTrailingArea_2>
                  <Wrapper.dashboardTrailingArea_3>
                    <IconChevronRight
                      data-testid="overview-icon-chevron-right"
                      color="#027AAE"
                      size={22}
                    />
                  </Wrapper.dashboardTrailingArea_3>
                </Wrapper.dashboardTrailingArea_1>
              }
            />
          </Wrapper.dashboardOverviewNavigation>
          <div style={{ padding: '0px 24px 26.5px 24px' }}>
            <List data-testid="overview-status-list">
              <Wrapper.listItemComp1
                data-testid="overview-status-listItem-1"
                leadingArea={<Wrapper.dashboardLeadingArea />}
                centerArea={
                  <Wrapper.dashboardcenterArea>
                    {t(
                      'ecp-endpointsecurity-dashboard.status.deviceStatus.online',
                      'Online'
                    )}
                  </Wrapper.dashboardcenterArea>
                }
                trailingArea={
                  <Wrapper.dashboardTrailingArea_4>
                    {devicesCount ? (
                      devicesCount?.onlineCount
                    ) : props.error !== null ? (
                      0
                    ) : (
                      <ProgressIndicator />
                    )}
                  </Wrapper.dashboardTrailingArea_4>
                }
              />
              <ListItem
                data-testid="overview-status-listItem-2"
                leadingArea={<Wrapper.dashboardLeadingArea_2 />}
                centerArea={
                  <Wrapper.dashboardcenterArea_2>
                    {t(
                      'ecp-endpointsecurity-dashboard.status.deviceStatus.offline',
                      'Offline'
                    )}
                  </Wrapper.dashboardcenterArea_2>
                }
                trailingArea={
                  <Wrapper.dashboardTrailingArea_5>
                    {devicesCount ? (
                      devicesCount?.offlineCount
                    ) : props.error !== null ? (
                      0
                    ) : (
                      <ProgressIndicator />
                    )}
                  </Wrapper.dashboardTrailingArea_5>
                }
              />
            </List>

            <Wrapper.dashboardOverviewDivider />

            <List data-testid="overview-status-list">
              <ListItem
                data-testid="overview-status-listItem-3"
                leadingArea={
                  <Wrapper.dashboardLeadingArea_3>
                    {t(
                      'ecp-endpointsecurity-dashboard.status.compliantStatus.compliantTitle',
                      'Devices Compliant'
                    )}
                  </Wrapper.dashboardLeadingArea_3>
                }
                trailingArea={
                  <Wrapper.dashboardTrailingArea_6>
                    {!isNaN(summaryValue.compliant) ? (
                      summaryValue.compliant
                    ) : (
                      <ProgressIndicator />
                    )}
                  </Wrapper.dashboardTrailingArea_6>
                }
              />
              <ListItem
                data-testid="overview-status-listItem-4"
                leadingArea={
                  <Wrapper.dashboardLeadingArea_4>
                    {t(
                      'ecp-endpointsecurity-dashboard.status.compliantStatus.nonCompliantTitle',
                      'Devices Noncompliant'
                    )}
                  </Wrapper.dashboardLeadingArea_4>
                }
                trailingArea={
                  <Wrapper.dashboardTrailingArea_7>
                    {!isNaN(noncomp) ? noncomp : <ProgressIndicator />}
                  </Wrapper.dashboardTrailingArea_7>
                }
              />
            </List>
          </div>
        </Wrapper.dashboardSecurityStatusContent>
      </Wrapper.dashboardSecurityStatusCard>
    )
  }

  const blankCardStatus = () => {
    return (
      <Wrapper.dashboardSecurityStatusCard>
        <Header
          data-testid="overview-header-section"
          LeftArea={
            <Wrapper.dashboardOverviewHeaderSection_LeftArea>
              {t('ecp-endpointsecurity-dashboard.status.header', 'Status')}
            </Wrapper.dashboardOverviewHeaderSection_LeftArea>
          }
          RightArea={''}
        />

        <Wrapper.dashboardOverviewNoDevices>
          <ProgressIndicator />
        </Wrapper.dashboardOverviewNoDevices>
      </Wrapper.dashboardSecurityStatusCard>
    )
  }

  if (Object.keys(summaryValue).length >= 1) {
    return (
      <Wrapper.assessmentStatusCard>
      <Card
        data-testid="overview-card"
        content={status() as any}
        background="filled"
        border="dropShadow"
      />
      </Wrapper.assessmentStatusCard>
    )
  } else
    return (
      <Wrapper.assessmentStatusCard>
      <Card
        data-testid="overview-card"
        content={blankCardStatus() as any}
        background="filled"
        border="dropShadow"
      />
      </Wrapper.assessmentStatusCard>
    )
}

export default SecurityAssessmentStatus
